:root {
    --main-font-family: 'Consolas';
    --main-background-color: #282c34;
    --card-background-color: rgb(37, 40, 55);
    --main-color: white;
    --shy-color: #555;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--main-color);
    background-color: var(--main-background-color);
}

